// Файл для подключения шрифтов и библиотек.
// Писать в этом файле свои стили ОЧЕНЬ не рекомендуется, все стили должны быть разнесены по блокам.
@import '~style';
@include site-fonts;
@include foundation-global-styles;

body {
	// Почему минимальная ширина не прописана в foundation не знаю, но она нужна, поэтому пришлось запихнуть в этот файл
	min-width: 320px;
}

main {
	// Если есть сомнения о необходимости примения стилей ко всему содержимому тега, то переносим в нужные блоки/элементы
	@include wysiwyg;
}
