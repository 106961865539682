@import '~style';

$block-name: 'b-header';

.#{$block-name} {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	border-bottom: 2px solid transparent;
	transition: background 0.3s, border 0.3s;

	&._bg {
		background-color: $color-white;
		border-bottom: 2px solid $color-link-blue;

		.#{$block-name} {
			&__menu-button {
				span {
					background-color: $color-black;
				}
			}

			&__main {
				display: none;
			}

			&__main-white {
				display: block;
			}
		}
	}

	&__logo-up {
		cursor: pointer;
	}

	&__container {
		@include blockContainer;

		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__main {
		display: block;
		text-decoration: none;
	}

	&__main-white {
		display: none;
		text-decoration: none;
	}

	&__right-block {
		align-items: center;
		display: none;

		@include breakpoint(xlarge) {
			display: flex;
		}
	}

	&__phone {
		padding: 8px 12px 6px;
		background-color: $color-orange;
		margin-left: 5px;
		display: flex;
		text-decoration: none;
	}

	&__phone-icon {
		margin-top: 2px;
	}

	&__phone-link {
		margin-left: 10px;
		color: $color-white;
	}

	&__menu-button {
		cursor: pointer;
		position: relative;
		z-index: 10;

		span {
			display: block;
			width: 20px;
			height: 2px;
			background-color: $color-white;

			&:nth-child(2) {
				&::before,
				&::after {
					transition: transform 0.3s;
				}
			}

			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}

		&._active {
			width: 20px;
			height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				background-color: $color-black;

				&:nth-child(2) {
					position: relative;
					background-color: rgba($color-white, 0);

					&::before,
					&::after {
						z-index: 10;
						background-color: $color-black;
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}

					&::before {
						transform: rotate(45deg);
					}

					&::after {
						transform: rotate(-45deg);
					}
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
				}

				&:nth-child(1),
				&:nth-child(3) {
					display: none;
				}
			}
		}

		@include breakpoint(xlarge) {
			display: none;
		}
	}

	&__mobile-menu {
		position: absolute;
		top: 0;
		left: -110%;
		width: 100vw;
		height: 100vh;
		background-color: $color-white;
		transition: left 0.3s;

		&._active {
			left: 0;
		}
	}

	&__opacity {
		position: absolute;
		top: 0;
		left: 0;
		display: none;
		width: 100%;
		height: 100%;
		background-color: rgba($color-black, 0.7);

		&._active {
			display: block;
		}
	}
}
