@mixin site-fonts() {
	// Подключайте тут все используемые шрифты,
	// ниже представлен пример использования миксина, не рекомендую его править (лучше скопировать строчку)
	// @include font-face($name: 'Open Sans', $path: 'normal-italic', $weight: normal, $style: italic, $woff2: true);

	$src: 'font/';

	@include font-face($name: 'Montserrat', $path: $src + 'Montserrat-normal', $weight: 400, $style: normal, $woff2: false);
	@include font-face($name: 'Montserrat', $path: $src + 'Montserrat-bold', $weight: 600, $style: normal, $woff2: false);
}
