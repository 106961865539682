@import '~style';

$block-name: 'b-subscribe-form';

.#{$block-name} {
	&__form-click {
		display: block;
		border: 1px solid $color-orange;
		background-color: $color-white;
		width: 100%;
		padding: 20px 0 18px;
		text-align: center;
		cursor: pointer;
		color: $color-orange;
		font-size: 14px;
		font-weight: 600;
		transition: color 0.3s, background 0.3s;

		&:hover {
			color: $color-white;
			background-color: $color-orange;
		}
	}

	&__form-modal {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba($color-black, 0.7);
		z-index: 2000;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__container-close {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	&__container {
		padding: 0 30px;
		position: relative;
		width: 100%;
		max-width: 400px;

		@include breakpoint(medium) {
			padding: 0;
		}
	}

	&__top {
		background-color: $color-green-blue;
		font-size: 20px;
		font-weight: 600;
		color: $color-white;
		padding: 35px 20px;
		text-align: center;

		@include breakpoint(medium) {
			padding: 35px 50px;
		}
	}

	&__bottom {
		background-color: $color-white;
		color: $color-black;
		font-size: 16px;
	}

	&__exit {
		position: absolute;
		right: 40px;
		top: 10px;
		width: 30px;
		height: 30px;
		cursor: pointer;

		span {
			display: block;
			position: absolute;
			top: 13px;
			left: 8px;
			width: 15px;
			height: 2px;
			background-color: $color-black;

			&:first-child {
				transform: rotate(45deg);
			}

			&:last-child {
				transform: rotate(-45deg);
			}
		}

		@include breakpoint(medium) {
			right: 10px;
			top: 10px;
		}
	}

	&__title {
		font-size: 20px;
		font-weight: 600;
		margin-top: 45px;

		@include breakpoint(medium) {
			font-size: 24px;
		}
	}

	&__text {
		font-size: 14px;
		margin-top: 40px;
		text-align: center;
		font-weight: 400;

		a {
			text-decoration: none;
			color: $color-white;
		}

		@include breakpoint(medium) {
			font-size: 16px;
		}
	}

	&__form {
		width: 100%;
		background-color: $color-white;
		padding: 35px 20px;

		@include breakpoint(medium) {
			padding: 35px 50px;
		}
	}

	&__input {
		width: 100%;
		max-width: 312px;
		padding: 12px 16px 13px;
		outline: none;
		border: 1px solid $color-form-border;

		&:not(:last-child) {
			margin-bottom: 15px;
		}

		&:focus-visible {
			outline: none;
		}

		&::placeholder {
			color: $color-form-placeholder;
			font-size: 14px;
		}

		&._invalid {
			border-bottom: 1px solid $color-red;

			&::placeholder {
				color: $color-red;
			}
		}
	}

	&__button {
		margin-top: 35px;
	}

	&__submit {
		background-color: $color-white;
		color: $color-orange;
		font-size: 16px;
		border: 1px solid $color-orange;
		padding: 15px 30px;
		width: 100%;
		cursor: pointer;
		font-weight: 600;
		transition: color 0.3s, background-color 0.3s;

		&:hover {
			background-color: $color-orange;
			color: $color-white;
		}
	}
}
