@import '~style';

$block-name: 'b-mobile-menu';
$block-menu: 'b-menu';

.b-header__mobile-menu {
	.#{$block-name} {
		@include blockContainer;

		&__logo {
			margin-top: 30px;
			margin-bottom: 40px;
			text-decoration: none;
		}

		&__phone {
			margin-top: 40px;
		}

		&__phone-link {
			text-decoration: none;
			color: $color-black;
			font-weight: 600;
		}
	}

	.#{$block-menu} {
		&__item {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		&__link {
			font-size: 13px;
			color: $color-black;
			text-decoration: none;
			transition: color 0.3s;

			&:hover {
				color: $color-green-blue;
			}
		}
	}
}
