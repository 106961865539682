@import '~style';

$block-name: 'b-common-banner';

.#{$block-name} {
	&__picture {
		width: 100%;
		height: 106px;

		// @include breakpoint(large) {
		// 	height: 120px;
		// }

		// @include breakpoint(xlarge) {
		// 	height: 140px;
		// }
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
