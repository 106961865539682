@import '~style';

$block-name: 'b-layout';

body.hidden {
	overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
}

.#{$block-name} {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	&__header {
		order: 0;
	}

	&__content {
		order: 1;
		flex-grow: 1;
	}

	&__footer {
		order: 2;
	}
}
