@import '~style';

$block-name: 'b-about-text';

.#{$block-name} {
	margin-top: 35px;

	&__container {
		@include blockContainer;

		max-width: 1180px;
	}

	&__line {
		display: block;
		width: 100%;
		max-width: 185px;
		height: 2px;
		background-color: $color-green-blue;
		margin: 0 auto;
	}

	&__text {
		font-size: 18px;
		line-height: 35px;
		text-align: center;
		margin-top: 20px;

		@include breakpoint(smedium) {
			font-size: 25px;
		}
	}
}
