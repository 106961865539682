@import '~style';

$block-name: 'b-news-slider';

.#{$block-name} {
	margin-top: 25px;
	margin-bottom: 95px;

	&__container {
		@include blockContainer;
	}

	&__title {
		font-size: 28px;
		margin-bottom: 40px;

		@include breakpoint(medium) {
			font-size: 36px;
		}
	}

	&__slide {
		// width: ;
	}

	&__item {
		text-decoration: none;
		display: block;
		position: relative;
		max-width: 100%;
		height: 450px;
		cursor: pointer;

		&:hover {
			.#{$block-name} {
				&__bg {
					background-color: $color-green-blue;
				}
			}
		}

		@include breakpoint(medium) {
			max-width: 445px;
			height: 420px;
		}
	}

	&__picture {
		width: 100%;
		height: 100%;
		position: absolute;
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color-link-blue, 0.6);
		transition: background 0.3s;
	}

	&__item-content {
		padding: 40px 20px 0;
		color: $color-white;
		position: relative;
		z-index: 10;

		@include breakpoint(xlarge) {
			padding: 70px 30px 0;
		}
	}

	&__item-title {
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 20px;
	}

	&__announce {
		font-size: 14px;
	}

	&__date {
		color: $color-white;
		position: absolute;
		bottom: 20px;
		left: 20px;
		font-size: 14px;
		font-weight: 600;

		@include breakpoint(medium) {
			font-size: 16px;
		}

		@include breakpoint(xlarge) {
			left: 30px;
		}
	}

	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__arrows {
		display: flex;
	}

	&__next,
	&__prev {
		width: 12px;
		height: 23px;
		cursor: pointer;
	}

	&__next {
		transform: rotate(180deg);
		margin-left: 25px;
	}

	&__button {
		margin-top: 40px;
		display: flex;
		justify-content: center;

		@include breakpoint(large) {
			margin-top: 60px;
		}
	}

	&__news-link {
		text-decoration: none;
		color: $color-white;
		padding: 14px 38px;
		background-color: $color-orange;
		border: 1px solid $color-orange;
		transition: background 0.3s, color 0.3s;

		&:hover {
			background-color: $color-white;
			color: $color-orange;
		}
	}
}
