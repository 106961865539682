@import '~style';

$block-name: 'b-techart-copyright';

.#{$block-name} {
	margin-top: 20px;
	font-size: 16px;

	&__link {
		color: $color-link-blue;
		text-decoration: none;
		transition: color 0.3s;

		&:hover {
			color: $color-green-blue;
		}
	}
}
