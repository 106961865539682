@mixin wysiwyg() {
	h1,
	.h1,
	.c-h1 {
		color: $body-font-color;
		font-weight: $global-weight-bold;
		line-height: 1;
	}

	h2,
	.h2,
	.c-h2 {
		color: $body-font-color;
		font-weight: $global-weight-normal;
		line-height: 1;
	}

	h3,
	.h3,
	.c-h3 {
		color: $color-gray-dark;
		font-weight: $global-weight-normal;
	}

	big,
	.big,
	.c-big {
		color: $color-black;
		font-size: $global-font-size * 1.5;
	}

	b,
	strong {
		font-weight: $global-weight-bold;
	}

	hr {
		border: none;
		border-bottom: 2px solid $color-gray-dark;
	}

	ul {
		padding-left: 30px;

		li {
			margin-bottom: 10px;
		}
	}

	ol {
		padding-left: 30px;

		li {
			margin: 10px 0;
		}
	}

	a {
		color: $color-gray;

		&:hover {
			text-decoration: none;
		}
	}

	.c-right {
		float: right;
		margin: 0 0 10px 10px;
	}

	.c-left {
		float: left;
		margin: 0 10px 10px 0;
	}

	.c-black {
		color: $color-black;
	}

	.c-gray {
		color: $color-gray-dark;
	}

	.c-hidden {
		display: none;
	}

	@each $name, $value in $breakpoints {
		.c-#{$name}-hidden {
			@include breakpoint(#{$name} only) {
				display: none;
			}
		}
	}
}
