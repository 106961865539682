@import '~style';

$block-name: 'b-top-block';

.#{$block-name} {
	position: relative;
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);

	@include breakpoint(large) {
		height: 650px;
	}

	&__video-container {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}

	&__video {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	&__container {
		padding: 0 30px;
		position: relative;
		z-index: 10;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__title {
		padding: 35px 40px 30px;
		border: 10px solid $color-green-blue;
		text-align: center;
		color: $color-white;
		font-size: 20px;
		font-weight: 600;
		max-width: 685px;

		@include breakpoint(smedium) {
			padding: 40px 110px 35px;
			font-size: 26px;
			border: 10px solid $color-green-blue;
		}

		@include breakpoint(medium) {
			font-size: 48px;
			line-height: 56px;
			padding: 57px 110px 50px;
			border: 15px solid $color-green-blue;
		}
	}
}
