@import '~style';

$block-name: 'b-footer';

.#{$block-name} {
	background-color: $color-light-gray;
	padding: 37px 0;
	font-size: 16px;

	&__container {
		@include blockContainer;

		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include breakpoint(xlarge) {
			flex-wrap: nowrap;
		}
	}

	&__person {
		width: 100%;
		margin-bottom: 30px;

		@include breakpoint(medium) {
			width: 50%;
		}

		@include breakpoint(xlarge) {
			max-width: 470px;
			width: 100%;
			margin-bottom: 0;
		}
	}

	&__person-copy {
		color: $color-footer-gray;
		max-width: 500px;
	}

	&__address {
		width: 100%;
		color: $color-footer-gray;
		margin-bottom: 30px;

		@include breakpoint(medium) {
			width: 50%;
			margin-bottom: 0;
		}

		@include breakpoint(xlarge) {
			max-width: 470px;
			width: 100%;
		}
	}

	&__address-text {
		max-width: 345px;
	}

	&__contacts {
		width: 100%;
		margin-bottom: 30px;
		display: flex;
		flex-direction: column;

		@include breakpoint(medium) {
			width: 50%;
			margin-bottom: 0;
		}

		@include breakpoint(xlarge) {
			width: 100%;
			max-width: 360px;
		}
	}

	&__contact {
		&:not(:last-child) {
			margin-bottom: 3px;
		}
	}

	&__label {
		color: $color-footer-gray;
	}

	&__phone {
		text-decoration: none;
		color: $color-link-blue;
		transition: color 0.3s;

		&:hover {
			color: $color-orange;
		}
	}

	&__email {
		transition: color 0.3s;
		color: $color-link-blue;

		&:hover {
			color: $color-orange;
		}
	}

	&__logo {
		width: 100%;

		@include breakpoint(medium) {
			width: 50%;
		}

		@include breakpoint(xlarge) {
			width: initial;
		}
	}

	&__policies {
		margin-top: 20px;
		display: inline-flex;
		flex-direction: column;
		row-gap: 5px;

		a {
			color: $color-link-blue;
			transition: color 0.3s;

			&:hover {
				color: $color-orange;
			}
		}
	}
}
