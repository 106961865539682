@import '~style';

$block-name: 'b-text-with-bg';

.#{$block-name} {
	padding: 40px 30px;
	background-color: $color-items;
	font-size: 14px;

	@include breakpoint(medium) {
		font-size: 16px;
	}

	ul {
		margin-block-start: 0;
		margin-block-end: 0;
		margin-inline-start: 0;
		margin-inline-end: 0;
		padding-inline-start: 20px;

		@include breakpoint(medium) {
			padding-inline-start: 30px;
		}

		@include breakpoint(large) {
			padding-inline-start: 40px;
		}
	}

	li {
		list-style: none;
		position: relative;
		margin: 10px 0;

		&::before {
			content: '';
			display: block;
			width: 6px;
			height: 1px;
			background-color: $color-link-blue;
			position: absolute;
			top: 8px;
			left: -14px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		@include breakpoint(medium) {
			font-size: 16px;
		}
	}
}
