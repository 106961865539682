@import '~style';

$block-name: 'b-title-with-ul';

.#{$block-name} {
	&__title {
		font-size: 20px;
		font-weight: 600;
		margin-bottom: 20px;
		color: $color-black;
	}

	&__text {
		font-size: 14px;
		margin-bottom: 20px;

		@include breakpoint(medium) {
			font-size: 16px;
		}
	}

	&__items {
		list-style: none;
	}

	ul {
		margin-block-start: 0;
		margin-block-end: 0;
		margin-inline-start: 0;
		margin-inline-end: 0;
		padding-inline-start: 20px;

		@include breakpoint(medium) {
			padding-inline-start: 30px;
		}

		@include breakpoint(large) {
			padding-inline-start: 40px;
		}
	}

	&__item {
		position: relative;
		font-size: 14px;
		margin-bottom: 20px;

		li {
			margin: 10px 0;
			list-style-type: disc;
		}

		&::before {
			content: '';
			display: block;
			width: 6px;
			height: 1px;
			background-color: $color-link-blue;
			position: absolute;
			top: 8px;
			left: -14px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		@include breakpoint(medium) {
			font-size: 16px;
		}
	}
}
