@import '~style';

$block-name: 'b-bread-crumbs';

.#{$block-name} {
	margin-top: 50px;

	&._down {
		margin-top: 106px;

		@include breakpoint(xlarge) {
			margin-top: 50px;
		}
	}

	&__container {
		@include blockContainer;
	}

	&__list {
		list-style: none;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	&__item {
		font-size: 14px;
		display: flex;
	}

	&__link {
		text-decoration: none;
		color: $color-black;
		transition: color 0.3s;

		&:hover {
			color: $color-link-blue;
		}
	}

	&__current {
		color: $color-gray-opacity;
	}

	&__arrow {
		display: flex;
		align-items: center;
		height: 18px;
		margin: 0 10px;
	}
}
